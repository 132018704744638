.animated {
  animation-duration: 0.25s;
  animation-fill-mode: both;
}


@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  animation-name: fadeOutDown; }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  animation-name: fadeOutUp; }

  @keyframes fadeOutLeft {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  }

  .fadeOutLeft {
    animation-name: fadeOutLeft;
  }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInDown {
  animation-name: fadeInDown; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInUp {
  animation-name: fadeInUp; }

/**
 * Vue Transitions
 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms ease-out; }

.fade-enter,
.fade-leave-to {
  opacity: 0; }

.zoom-in-enter-active,
.zoom-in-leave-active {
  transition: opacity 150ms ease-out; }
  .zoom-in-enter-active .animation-content,
  .zoom-in-enter-active .animation-content,
  .zoom-in-leave-active .animation-content,
  .zoom-in-leave-active .animation-content {
    transition: transform 150ms ease-out; }

.zoom-in-enter,
.zoom-in-leave-active {
  opacity: 0; }
  .zoom-in-enter .animation-content,
  .zoom-in-enter .animation-content,
  .zoom-in-leave-active .animation-content,
  .zoom-in-leave-active .animation-content {
    transform: scale(0.95); }

.zoom-out-enter-active,
.zoom-out-leave-active {
  transition: opacity 150ms ease-out; }
  .zoom-out-enter-active .animation-content,
  .zoom-out-enter-active .animation-content,
  .zoom-out-leave-active .animation-content,
  .zoom-out-leave-active .animation-content {
    transition: transform 150ms ease-out; }

.zoom-out-enter,
.zoom-out-leave-active {
  opacity: 0; }
  .zoom-out-enter .animation-content,
  .zoom-out-enter .animation-content,
  .zoom-out-leave-active .animation-content,
  .zoom-out-leave-active .animation-content {
    transform: scale(1.05); }

.slide-next-enter-active,
.slide-next-leave-active,
.slide-prev-enter-active,
.slide-prev-leave-active {
  transition: transform 250ms cubic-bezier(0.785, 0.135, 0.15, 0.86); }

.slide-prev-leave-to, .slide-next-enter {
  transform: translate3d(-100%, 0, 0);
  position: absolute;
  width: 100%; }

.slide-prev-enter, .slide-next-leave-to {
  transform: translate3d(100%, 0, 0);
  position: absolute;
  width: 100%; }